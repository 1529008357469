



































































































































import { Component, Vue, Emit } from 'vue-property-decorator';
import { IRecruitParams, CountryLangIds } from '@/interfaces';
import { readUserProfile } from '@/store/main/getters';
import { readSessionData } from '@/store/sessionData/getters';
import { readNumberOfParticipants } from '@/store/recruitData/getters';
import { readHasAdminAccess } from '@/store/main/getters';
import {
  dispatchGetNumberOfParticipants
} from '@/store/recruitData/actions';
import store from '@/store';

@Component
export default class RecruitExternal extends Vue {
  public selectedCountries = { 'US': { selected: true, id: 9 }, 'UK': { selected: true, id: 8 } };
  public valid = true;
  public confirmRecruitDialog = false;
  public getCreditsDialog = false;

  public quota = 1;
  public gender_balance = 50;
  public age_range = [18, 99];
  public nrOfMatchingParticipants = 0;

  public get hasAdminAccess() {
    return readHasAdminAccess(this.$store);
  }

  public params: IRecruitParams = {
    country_lang_ids: this.selectedCountryIds,
    quota: this.quota,
    age_range: this.age_range,
    gender_balance: this.gender_balance
  }

  get selectedCountryIds() {
    const ids: CountryLangIds[] = [];
    for (const [key, value] of Object.entries(this.selectedCountries)) {
      if (value.selected) {
        ids.push(value.id);
      }
    }
    return ids;
  }

  async onAgeRangeChanged() {
    this.params.age_range = this.age_range;
    await this.getNumberOfParticipants();
  }

  async onGenderBalanceChanged() {
    this.params.gender_balance = this.gender_balance;
    await this.getNumberOfParticipants();
  }

  quotaChanged() {
    this.params.quota = this.quota;
  }

  get nrOfQuestions() {
    return readSessionData(this.$store).questions_with_data.length;
  }

  get nrOfParticipants() {
    return readNumberOfParticipants(this.$store);
  }

  get enoughCredits() {
    return (
      this.quota * this.nrOfQuestions <= readUserProfile(this.$store)!.credits
    );
  }

  get userCredits() {
    return (
      readUserProfile(this.$store)!.credits
    );
  }

  public countrySelect = ['UK', 'US'];

  public reformattedCountry = {
    'UK': 'UK',
    'US': 'US',
  }

  public selectedPanelCompany = {
    'UK': ' via Prolific',
    'US': ' via Prolific',
  }

  public maxValue() {
    if (this.hasAdminAccess) {
      return 1000;
    }
    else {
      return 500;
    }
  }

  async created() {
    await this.getNumberOfParticipants();
  }

  public resetForm() {
    this.valid = true;
    this.confirmRecruitDialog = false;
    this.getCreditsDialog = false;
    this.quota = 1;
    this.gender_balance = 50;
    this.age_range = [18, 99];
    this.nrOfMatchingParticipants = 0;
  }

  @Emit()
  public close() {
    return;
  }

  @Emit()
  public async save() {
    this.confirmRecruitDialog = false;
    if (await this.$validator.validateAll()) {
      const params = JSON.parse(JSON.stringify(this.params));
      this.resetForm();
      return params;
    }
  }

  async onClickCountryFlag(lang) {
    this.selectedCountries[lang].selected = !this.selectedCountries[lang].selected;
    this.params.country_lang_ids = this.selectedCountryIds;
    await this.getNumberOfParticipants();
  }

  public loading = false;
  async getNumberOfParticipants() {
    this.loading = true;
    await dispatchGetNumberOfParticipants(store, { id: this.$route.params.id, params: this.params });
    this.loading = false;
  }
}
