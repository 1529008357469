







































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { IQuestionCreate, QuestionTypes } from '@/interfaces';
import { readToken } from '@/store/main/getters';
import { api } from '@/api';
import { commitAddNotification } from '@/store/main/mutations';
import { dispatchUpdateContract } from '@/store/sessionData/actions';

@Component
export default class QuestionsList extends Vue {
  @Prop(Object) public question: IQuestionCreate | undefined;
  @Prop(Number) public questionIndex!: number;

  public questionDialogOpen = false;
  public valid = false;
  public currentQuestion = {
    title: '',
    context: '',
    type: QuestionTypes.binary,
    contracts: ['', '', ''],
  };

  public types = [
    { text: 'Binary question', value: QuestionTypes.binary, bool: false },
    { text: 'Scenario question ', value: QuestionTypes.scenario, bool: false },
    { text: 'Open Ended question ', value: QuestionTypes.open, bool: false },
  ];

  public contracts: string[] = ['', '', ''];

  public created() {
    this.setQuestions();
  }

  public addScenario() {
    this.focusTitle = false;
    this.contracts.push('');
  }

  public async pushQuestion() {
    if (await this.$validator.validateAll()) {
      const filtered = this.contracts.filter(function (obj) {
        return obj.length;
      });
      const updatedContracts = [
        ...new Set(
          filtered.map((obj) => {
            return obj.trim();
          })
        ),
      ];
      if (
        this.currentQuestion.type == QuestionTypes.binary ||
        (updatedContracts.length > 2 &&
          this.currentQuestion.type == QuestionTypes.scenario) ||
        (updatedContracts.length > 1 &&
          this.currentQuestion.type == QuestionTypes.nominal) ||
        this.currentQuestion.type == QuestionTypes.open
      ) {
        this.currentQuestion.contracts = updatedContracts;
        this.$emit(
          'pushUpdatedQuestion',
          this.currentQuestion,
          this.questionIndex
        );
        this.closeDialog();
      } else {
        const field = this.$validator.fields.find({ name: '0' });
        if (field) {
          this.$validator.errors.add({
            field: '0',
            msg: this.currentQuestion.type == QuestionTypes.scenario ?
              'You must have at least 3 unique scenarios. Please fix them to continue' :
              'You must have at least 2 unique options. Please fix them to continue',
          });
        }
      }
    }
  }

  public async closeWindow() {
    this.closeDialog();
    this.resetContracts();
    this.resetQuestion();
    this.setQuestions();
  }

  public setQuestions() {
    if (this.question) {
      this.currentQuestion.title = this.question.title;
      this.currentQuestion.context = this.question.context;
      this.currentQuestion.type = this.question.type;
      this.contracts = [];
      if (this.question.contracts) {
        this.question.contracts.map((obj, index) => {
          this.contracts.push(obj);
        });
      }
    }
  }

  public resetContracts() {
    this.contracts = [];
  }

  public resetQuestion() {
    this.currentQuestion = {
      title: '',
      context: '',
      type: QuestionTypes.binary,
      contracts: ['', '', ''],
    };
  }

  get buttonSize() {
    return this.$vuetify.breakpoint.name === 'xs' ? { ['small']: true } : {};
  }

  public focusTitle = true;

  public openDialog() {
    this.questionDialogOpen = true;
  }

  public closeDialog() {
    this.questionDialogOpen = false;
  }

  get questionDialog() {
    return this.questionDialogOpen;
  }

  public generateScenarioButtonText = 'Generate Scenario';
  public scenarioLoading = false;
  public async generateScenario() {
    this.generateScenarioButtonText = 'Generating...';
    const token = readToken(this.$store);
    this.scenarioLoading = true;
    try {
      const response = await api.generateSingleScenario(token, {
        study_title: this.currentQuestion.title,
        question_title: this.currentQuestion.title,
        contracts: this.contracts,
      });
      if (this.contracts[this.contracts.length - 1].length < 1) {
        this.contracts[this.contracts.length - 1] = response.data;
      } else {
        this.contracts.push(response.data);
      }
    } catch (error) {
      commitAddNotification(this.$store, {
        content: 'Failed to generate scenario',
        color: 'error',
      });
    }
    this.generateScenarioButtonText = 'Generate Scenario';
    this.scenarioLoading = false;
  }
}
