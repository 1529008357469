






































import { Vue, Component, Prop } from 'vue-property-decorator';
import Chat from '@/views/study/views/Chat.vue';

@Component({
  components: {
    'Chat': Chat,
  },
})
export default class ChatPreview extends Vue {
  @Prop(String) public studyID!: string;
  public selectedTheme = 'dark';
  public chatStyle?: object = undefined;
  public chatLogo = '';
  public chatLogoEnabled = false;
  public themes = {
    'green': {
      'chat-theme': 'green',
      'chat-content-bg-color': '#F5F5F6',
      'chat-header-bg-color': '#004d2d',
      'chat-message-bg-color': '#197a56',
      'chat-message-bg-color-me': '#50aa83',
      'chat-color-button': '#F5F5F6',
      'chat-footer-bg-color': '#004d2d',
      'chat-bg-color-input': '#50aa83',
      'chat-color-placeholder': '#004d2d',
      'chat-message-color': '#ffffff',
      'chat-color': '#ffffff'
    },
    'light': {
      'chat-theme': 'light',
      'chat-content-bg-color': '#F5F5F6',
      'chat-header-bg-color': '#494949',
      'chat-message-bg-color': '#757575',
      'chat-message-bg-color-me': '#a4a4a4',
      'chat-color-button': '#F5F5F6',
      'chat-footer-bg-color': '#494949',
      'chat-bg-color-input': '#a4a4a4',
      'chat-color-placeholder': '#494949',
      'chat-message-color': '#ffffff',
      'chat-color': '#ffffff'
    },
    'blue': {
      'chat-theme': 'blue',
      'chat-content-bg-color': '#F5F5F6',
      'chat-header-bg-color': '#0069c0',
      'chat-message-bg-color': '#2196f3',
      'chat-message-bg-color-me': '#6EC6FF',
      'chat-color-button': '#F5F5F6',
      'chat-footer-bg-color': '#0069c0',
      'chat-bg-color-input': '#6EC6FF',
      'chat-color-placeholder': '#0069c0',
      'chat-message-color': '#ffffff',
      'chat-color': '#ffffff'
    },
    'dark': {
      'chat-theme': 'dark',
      'chat-content-bg-color': '#131415',
      'chat-header-bg-color': '#181A1B',
      'chat-message-bg-color': '#222429',
      'chat-message-bg-color-me': '#346769',
      'chat-color-button': '#ffffff',
      'chat-footer-bg-color': '#181A1B',
      'chat-bg-color-input': '#202223',
      'chat-color-placeholder': '#596269',
      'chat-message-color': '#ffffff',
      'chat-color': '#ffffff'
    }
  }
  public setChatTheme(theme: string) {
    this.selectedTheme = theme;
    this.chatStyle = this.themes['dark'];
    this.chatStyle['chat-theme'] = this.selectedTheme;
  }

  public yourLogoText = 'YOUR LOGO';
  logoMouseOver() {
    this.yourLogoText = 'Click to upload logo';
  }
  logoMouseLeave() {
    this.yourLogoText = 'YOUR LOGO';
  }
}

